type SerachResultsUrlParams = {
	brandPath: string
	pathNameBase: string
	searchTerm?: string
	isNew?: boolean
}

const NEW_PATH = 'new'

export const searchUrl = ({
	brandPath,
	pathNameBase,
	searchTerm,
	isNew = false,
}: SerachResultsUrlParams) => {
	const newSearchPath = isNew ? `/${NEW_PATH}` : ''
	const pathName = `${pathNameBase}${brandPath}${newSearchPath}`

	if (!searchTerm) {
		return pathName
	}

	const searchParams = new URLSearchParams({ q: searchTerm })

	return `${pathName}?${searchParams}`
}
