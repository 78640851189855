import type React from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import iconsStyles from '../UserTools/Icons.module.scss'

export function UserToolsItem({
	children,
	shouldRender = true,
	fill,
}: {
	readonly children: React.ReactNode
	readonly shouldRender?: boolean
	readonly fill?: boolean
}) {
	if (!shouldRender) {
		return null
	}

	return (
		<li
			className={cx(iconsStyles.item, applyStylesIf(!!fill, iconsStyles.fill))}
		>
			{children}
		</li>
	)
}
