import type { Brand } from 'types/brands'
import type { BrandMenu } from '../components/Brands/Brands'

export const getActiveBrandTopbar = (
	brandSelected: string | null,
	open: boolean,
	isTopBar: boolean,
	brand: BrandMenu,
	defaultBrand?: Brand
) => {
	const isSelected = defaultBrand?.id === brand.id
	const isOpenTopBarSelected = open && isTopBar && brandSelected === brand.id
	const isOpenTopBarNotSelected = open && isTopBar && brandSelected !== brand.id

	return (
		isSelected ||
		isOpenTopBarSelected ||
		(isOpenTopBarNotSelected ? false : undefined)
	)
}
