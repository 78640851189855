import { useClientLabels } from 'labels/hooks/useClientLabels/useClientLabels'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useSetLabels } from 'labels/hooks/useSetLabels/useSetLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect } from 'react'
import { LabelsNamespaces } from 'types/labels'

import { useIsLabelReady } from './useIsLabelReady'
import { useMenuData } from './useMenuData'

export function useMenuLabels(firstOpen: boolean) {
	const {
		isShop,
		country: { countryISO, languageISO },
	} = useMasterData()
	const { menusData } = useMenuData(firstOpen)
	const { labels: layoutLabels } = useLabels()
	const setLabels = useSetLabels()

	const pickLabel = menusData?.[0]?.menus?.[0]?.labelId ?? ''
	const isMenuLabelsReady = useIsLabelReady(pickLabel)

	const menuNamespace = isShop
		? LabelsNamespaces.Menu_Shop
		: LabelsNamespaces.Menu_Outlet

	const { labels: labelsClient } = useClientLabels({
		namespaces: firstOpen ? [menuNamespace, LabelsNamespaces.Menu_Common] : [],
		suspense: false,
		countryISO,
		languageISO,
	})

	useEffect(() => {
		if (layoutLabels && labelsClient) {
			setLabels({
				...layoutLabels,
				...labelsClient,
			})
		}
	}, [labelsClient])

	return { isMenuLabelsReady }
}
