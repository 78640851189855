'use client'

import { ButtonInspirational } from '@fukku/button/Inspirational'
import { useCookiesBanner } from 'cookies/hooks/useCookiesBanner/useCookiesBanner'

import styles from '../LegalLinks.module.scss'

export function CookiesButton({
	cookiesBanner,
}: {
	readonly cookiesBanner: { key: string }
}) {
	const { showCookiesConfiguration } = useCookiesBanner()

	return (
		<ButtonInspirational
			className={styles.legalLink}
			onClick={showCookiesConfiguration}
		>
			{cookiesBanner.key}
		</ButtonInspirational>
	)
}
