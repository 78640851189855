export type ScreenSizes = 'small' | 'medium' | 'large' | 'xlarge'
export type SimpleScreenSizes = 'lessThanLarge' | 'largeOrGreater'
export type ResponsiveBehavior<T> = {
	small?: T
	medium?: T
	large?: T
	xlarge?: T
	default: T
}
export type ResponsiveBehaviorSimple<T> = {
	lessThanLarge?: T
	largeOrGreater?: T
	default: T
}
export type ResponsiveBehaviors<K extends string, T> = Record<
	K,
	ResponsiveBehavior<T> | ResponsiveBehaviorSimple<T>
>

function includeAllProperties<T>(
	object: Record<string, T> | undefined,
	properties: string[]
): boolean {
	return (
		!!object && Object.keys(object).every((key) => properties.includes(key))
	)
}

function includeAlmostOneProperty<T>(
	object: Record<string, T>,
	properties: string[]
): boolean {
	return Object.keys(object).some((key) => properties.includes(key))
}

function isObjetWithDefaultDefined<T>(
	object: Record<string, T> | undefined,
	props: string[]
) {
	return (
		!!object &&
		includeAlmostOneProperty<T>(object, props) &&
		object.default !== undefined
	)
}

export function isResponsiveBehavior<T>(
	obj: ResponsiveBehavior<T> | ResponsiveBehaviorSimple<T>
): obj is ResponsiveBehavior<T> {
	const props = ['small', 'medium', 'large', 'xlarge']
	return !!(
		isObjetWithDefaultDefined(obj, props) || includeAllProperties<T>(obj, props)
	)
}

export function isResponsiveBehaviorSimple<T>(
	obj: ResponsiveBehavior<T> | ResponsiveBehaviorSimple<T>
): obj is ResponsiveBehaviorSimple<T> {
	const props = ['lessThanLarge', 'largeOrGreater']
	return !!(
		isObjetWithDefaultDefined(obj, props) || includeAllProperties<T>(obj, props)
	)
}

/**
 *
 * @throws {Error} When the behavior properties are invalid
 */
export function getResponsiveBehaviors<K extends string, T>(
	behavior: ResponsiveBehaviors<K, T>,
	type: K,
	breakpoint: ScreenSizes | SimpleScreenSizes | 'default'
): T {
	const behaviorType = behavior[type]
	if (isResponsiveBehavior<T>(behaviorType)) {
		return behaviorType[breakpoint as ScreenSizes] ?? behaviorType.default
	}
	if (isResponsiveBehaviorSimple<T>(behaviorType)) {
		return behaviorType[breakpoint as SimpleScreenSizes] ?? behaviorType.default
	}
	throw new Error(
		`Invalid behavior properties ${type} ${JSON.stringify(behaviorType)}`
	)
}

/**
 *
 * @throws {Error} When the behavior properties are invalid
 */
export function getResponsiveBehavior<T>(
	behavior: ResponsiveBehavior<T> | ResponsiveBehaviorSimple<T>,
	breakpoint: ScreenSizes | SimpleScreenSizes | 'default'
): T {
	if (isResponsiveBehavior(behavior)) {
		return behavior[breakpoint as ScreenSizes] ?? behavior.default
	}
	if (isResponsiveBehaviorSimple(behavior)) {
		return behavior[breakpoint as SimpleScreenSizes] ?? behavior.default
	}
	throw new Error(`Invalid behavior properties ${JSON.stringify(behavior)}`)
}
