'use client'

import type { IconColor } from 'icons/types/Icon.types'

import { useLogoProperties } from './hooks/useLogoProperties'

type LogoSize = {
	width: number
	height: number
}

type LogoProps = {
	readonly className?: string
	readonly size?: LogoSize
	readonly color?: IconColor
}

export function Logo({ className = '', size, color }: LogoProps) {
	const { LogoComponent, name, altText, width, height } = useLogoProperties()

	return (
		<LogoComponent
			className={className}
			lang='en'
			alt={altText}
			width={size?.width ?? width}
			height={size?.height ?? height}
			data-testid={name}
			color={color}
		/>
	)
}
