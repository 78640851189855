'use client'

import type { ButtonProps } from '@fukku/button/Button.types'
import { ButtonPrimary } from '@fukku/button/Primary'
import { ButtonSecondary } from '@fukku/button/Secondary'
import { useFormContext } from 'react-hook-form'

type SubmitButtonProps = ButtonProps & {
	readonly secondary?: boolean
}

export function SubmitButton({
	secondary = false,
	children,
	...restProps
}: SubmitButtonProps): React.ReactNode {
	const {
		formState: { isSubmitting },
	} = useFormContext()

	const buttonProps = {
		isLoading: isSubmitting,
		...restProps,
	}

	const Button = secondary ? ButtonSecondary : ButtonPrimary

	return (
		<Button type={'submit'} {...buttonProps}>
			{children}
		</Button>
	)
}
