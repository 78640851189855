'use client'

import { InspirationalClientLink } from '@fukku/button/ClientLink/Inspirational'
import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents } from 'footer/constants'
import { DATATESTID_PREFIX } from 'footer/constants/translations'

import type { NavigationLink } from '../NavigationLinks'
import { StoreLocatorButton } from './StoreLocatorButton/StoreLocatorButton'

import styles from './NavigationLinkItem.module.scss'

type NavigationLinkItemProps = {
	readonly item: NavigationLink
}

export function NavigationLinkItem({ item }: NavigationLinkItemProps) {
	const { title, href, dataTestId } = item

	const normalizedDataTestId = dataTestId.replace(DATATESTID_PREFIX, '')

	if (!href) {
		return <StoreLocatorButton />
	}

	const handleLinkClick = () => {
		sendGenericEvent(CommonGenericEvents.LINK_SELECTED, {
			section: normalizedDataTestId,
		})
	}

	return (
		<InspirationalClientLink
			className={styles.navigationLink}
			href={href}
			data-testid={normalizedDataTestId}
			onClick={handleLinkClick}
		>
			{title}
		</InspirationalClientLink>
	)
}
