'use client'

import type { BUTTON_INSPIRATIONAL_VARIANT } from '@fukku/button/Inspirational/constants'
import type { ElementType } from 'react'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { HREF } from '../constants/constants'

type FavoritesClientLinkProps = Omit<
	ClientLinkProps,
	'href' | 'withLegacyLocale'
> & {
	readonly linkComponent?: ElementType
	readonly variant?: BUTTON_INSPIRATIONAL_VARIANT
}

export function FavoritesClientLink({
	children,
	linkComponent,
	variant,
	...linkProps
}: FavoritesClientLinkProps) {
	const Component = linkComponent || ClientLink

	return (
		<Component {...linkProps} href={HREF} variant={variant}>
			{children}
		</Component>
	)
}
