'use client'

import type { BUTTON_INSPIRATIONAL_VARIANT } from '@fukku/button/Inspirational/constants'
import type { IconColor } from 'icons/types/Icon.types'
import { createContext, useEffect, useState } from 'react'

type TransparentProps = {
	inspirationalVariant?: BUTTON_INSPIRATIONAL_VARIANT
	iconColor?: IconColor
}

type ContextProviderProps = {
	readonly children: React.ReactNode
	readonly useTransparentHook?: () => TransparentProps
}

const INITIAL_STATE: TransparentProps = {}

export const TransparentPropsContext = createContext<
	TransparentProps | undefined
>(undefined)

export function TransparentPropsProvider({
	children,
	useTransparentHook = () => INITIAL_STATE,
}: ContextProviderProps) {
	const { inspirationalVariant: variantHook, iconColor: iconHook } =
		useTransparentHook()

	const [state, setState] = useState({
		inspirationalVariant: variantHook ?? INITIAL_STATE.inspirationalVariant,
		iconColor: iconHook ?? INITIAL_STATE.iconColor,
	})

	useEffect(() => {
		setState({
			inspirationalVariant: variantHook,
			iconColor: iconHook,
		})
	}, [variantHook, iconHook])

	return (
		<TransparentPropsContext.Provider value={state}>
			{children}
		</TransparentPropsContext.Provider>
	)
}
