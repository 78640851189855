'use client'
import { useHeaderState } from 'header-utils/context/HeaderContext/useHeaderState'
import { HeaderSearchBox } from 'search/components/HeaderSearchBoxLegacy/HeaderSearchBox'

import iconsStyles from '../UserTools/Icons.module.scss'
import { UserToolsItem } from '../UserToolsItem/UserToolsItem'
export function SearchTool({
	shouldRender = true,
}: {
	readonly shouldRender?: boolean
}) {
	const { isSearchOpen } = useHeaderState()

	return (
		<UserToolsItem shouldRender={shouldRender} fill={isSearchOpen}>
			<HeaderSearchBox
				iconClassName={iconsStyles.icon}
				labelClassName={iconsStyles.text}
			/>
		</UserToolsItem>
	)
}
