import { useEffect } from 'react'
import { useHoverIntent } from 'react-use-hoverintent'

export function useHover<T>(delay = 0, callbackHover?: () => void) {
	const [isHovering, ref] = useHoverIntent<T>({
		interval: delay,
	})

	useEffect(() => {
		if (isHovering) {
			callbackHover?.()
		}
	}, [isHovering])

	return { ref, isHovering }
}
