'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import type { ChatbotFeatureFlag } from 'oldchatbot/types/ChatbotFeatureFlag'

import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { ChatbotButton } from '../ChatbotButton/ChatbotButton'

export const ChatbotContainer = () => {
	const { enabled: isChatbotEnabled, variables } =
		useFeatureFlag<ChatbotFeatureFlag>(featureFlagKeys.Chatbot)
	const { chatbotVersion } = variables ?? {}

	if (!isChatbotEnabled) {
		return null
	}

	return <ChatbotButton version={chatbotVersion} />
}
