'use client'

import { useHeaderState } from 'header-utils/context/HeaderContext/useHeaderState'
import { useMenuLabels } from 'menu/hooks/useMenuLabels'
import { useMenus } from 'menu/hooks/useMenus'
import dynamic from 'next/dynamic'
import { Responsive } from 'responsive/components/Responsive/Responsive'

import { OpenMenu } from '../OpenMenu/OpenMenu'

import styles from './WrapperMenu.module.scss'

const DynamicStructureLPlus = dynamic(() =>
	import('../Structure/StructureLPlus').then((mod) => mod.StructureLPlus)
)

const DynamicStructureSM = dynamic(() =>
	import('../Structure/StructureSM').then((mod) => mod.StructureSM)
)

const DynamicBackdrop = dynamic(() =>
	import('../Backdrop/Backdrop').then((mod) => mod.Backdrop)
)

export function WrapperMenu() {
	const { firstOpen, hasDataLoaded } = useMenus()
	const { isSearchOpen } = useHeaderState()
	const { isMenuLabelsReady } = useMenuLabels(firstOpen)

	if (isSearchOpen) {
		return null
	}

	return (
		<div
			className={styles.menu}
			data-testid='inspirational.menu'
			id='wrapperMenu'
		>
			<OpenMenu />
			{hasDataLoaded && firstOpen && isMenuLabelsReady && (
				<>
					<DynamicBackdrop />
					<Responsive onLargeOrGreater>
						<DynamicStructureLPlus />
					</Responsive>
					<Responsive onLessThanLarge>
						<DynamicStructureSM />
					</Responsive>
				</>
			)}
		</div>
	)
}
