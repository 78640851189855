'use client'

import { CART_PATH, PAGE_TYPE_SHOPPING_CART } from 'cart/constants/constants'
import { useCartState } from 'cart/context/CartProvider'
import { useCartController } from 'cart/hooks/useCartController'
import { useFeedbackTestVariable } from 'cart/hooks/useFeedbackTest'
import { FEEDBACK_ADD_TO_CART_VARIANTS } from 'cart/utils/mapFeedbackAddToCartVariant'
import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import dynamic from 'next/dynamic'
import type { ReactNode } from 'react'
import { Suspense, useEffect, useRef, useState } from 'react'
import { Keyboard } from 'types/keyboard'

import { ButtonLarge } from './ButtonLarge/ButtonLarge'

import styles from './ShoppingCartButton.module.scss'

const CLOSE_PREVIEW_DELAY = 350

const PreviewWrapper = dynamic(() =>
	import('../../Preview/PreviewWrapper/PreviewWrapper').then(
		(module) => module.PreviewWrapper
	)
)

const LimitsModal = dynamic(() =>
	import('../../../components/LimitsModal/LimitsModal').then(
		(module) => module.LimitsModal
	)
)

const AddNoStockModal = dynamic(() =>
	import('../../../components/AddNoStockModal/AddNoStockModal').then(
		(module) => module.AddNoStockModal
	)
)

export const ShoppingCartLarge = (): ReactNode => {
	const {
		showPreview,
		showShoppingCart,
		openedLimitWarningModal,
		openedAddNoStockModal,
	} = useCartState()
	const iconRef = useRef<HTMLDivElement>(null)
	const lastFocus = useRef<Element | null>(null)
	const { pageType } = useMasterData()
	const isCartPage = pageType === PAGE_TYPE_SHOPPING_CART
	const { toggle, close } = useCartController(isCartPage)
	const [previewLoaded, setPreviewLoaded] = useState(false)
	const { variant } = useFeedbackTestVariable()
	const { navigate } = useNavigation()

	/**
	 * It restores the focus to the last element that was focused before the shopping cart was opened
	 */
	const restoreFocus = () => {
		if (lastFocus.current) {
			;(lastFocus.current as HTMLElement).focus()
			lastFocus.current = null
		}
	}

	/**
	 * @param {boolean} itemRemoved when an item is removed we don't want to update the lastFocus
	 * because we are moving the focus to the shopping cart button
	 */
	const manageFocus = (itemRemoved: boolean) => {
		if (showPreview) {
			const { activeElement } = document
			if (!lastFocus.current && !itemRemoved) {
				lastFocus.current = activeElement
			}
			iconRef.current?.focus()
		}
	}

	/**
	 * Open or close the shopping cart preview.
	 * When it is toggled via keyboard the focus will be restored to the last element that was focused before the shopping cart was opened
	 */
	const handleToggle = (withKeyboard = false) => {
		const isToggled = toggle()
		// when keyboard action is going to close the shopping cart preview
		// we need to restore the focus to the last element that was focused
		// before the shopping cart was opened
		if (isToggled && withKeyboard && showPreview) {
			restoreFocus()
		}
	}

	/**
	 * It handles the keyboard events for the shopping cart button
	 * only ESC, Enter and Space keys will be handled
	 */
	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (!showShoppingCart) {
			if (
				e.key !== Keyboard.Enter &&
				e.key !== Keyboard.Space &&
				e.key !== Keyboard.Escape
			) {
				return
			}
			e.preventDefault()
			e.stopPropagation()

			if (e.key === Keyboard.Enter || e.key === Keyboard.Space) {
				const withKeyboard = true
				handleToggle(withKeyboard)
			}

			if (e.key === Keyboard.Escape && showPreview) {
				close()
				restoreFocus()
			}
		}
	}

	const handleClick = (e: React.MouseEvent) => {
		if (!showShoppingCart) {
			e.preventDefault()
			e.stopPropagation()
			if (variant !== FEEDBACK_ADD_TO_CART_VARIANTS.DEFAULT) {
				navigate(CART_PATH)
			} else {
				handleToggle()
			}
		}
	}

	useEffect(() => {
		if (showPreview) {
			setPreviewLoaded(true)
			manageFocus(false)
		} else {
			setTimeout(() => {
				setPreviewLoaded(false)
			}, CLOSE_PREVIEW_DELAY)
		}
	}, [showPreview])

	return (
		<div className={styles.userIconContainer}>
			<ButtonLarge
				handleClick={handleClick}
				handleKeyDown={handleKeyDown}
				iconRef={iconRef}
				manageFocus={manageFocus}
			/>

			{previewLoaded && (
				<Suspense fallback={null}>
					<PreviewWrapper iconRef={iconRef} handleToggle={handleToggle} />
				</Suspense>
			)}
			{openedLimitWarningModal && (
				<Suspense fallback={null}>
					<LimitsModal />
				</Suspense>
			)}
			{openedAddNoStockModal && (
				<Suspense fallback={null}>
					<AddNoStockModal />
				</Suspense>
			)}
		</div>
	)
}
