import { useTransparentProps } from 'header-utils/context/TransparentPropsContext/useTransparentProps'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { cx } from 'utils/cx'

import { TotalItems } from '../../TotalItems/TotalItems'

import text from 'fukku/styles/texts.module.scss'
import styles from './ButtonLarge.module.scss'

type ButtonLargeProps = {
	readonly handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
	readonly handleKeyDown: (
		event: React.KeyboardEvent<HTMLButtonElement>
	) => void
	readonly iconRef: React.RefObject<HTMLDivElement>
	readonly manageFocus: (itemRemoved: boolean) => void
}

export function ButtonLarge({
	handleClick,
	handleKeyDown,
	iconRef,
	manageFocus,
}: ButtonLargeProps) {
	const { t } = useLabels()
	const { inspirationalVariant } = useTransparentProps()

	return (
		<button
			type='button'
			className={cx(
				styles.totalItemsContainer,
				inspirationalVariant && styles[inspirationalVariant]
			)}
			onKeyDown={handleKeyDown}
			onClick={handleClick}
		>
			<div
				data-testid={'header.userMenu.cart.button'}
				className={cx(styles.text, styles.totalItemsText, text.uppercaseM)}
				ref={iconRef}
				tabIndex={-1}
			>
				<span>{t('header.shoppingBag.bag.text')}</span>
			</div>

			<div
				className={cx(styles.text, styles.totalItemsNumber, text.uppercaseM)}
			>
				<TotalItems withLabel manageFocus={manageFocus} />
			</div>
		</button>
	)
}
