'use client'

import { InspirationalClientLink } from '@fukku/button/ClientLink/Inspirational'
import { useTransparentProps } from 'header-utils/context/TransparentPropsContext/useTransparentProps'
import { useNavigationBrand } from 'hooks/useNavigationBrand/useNavigationBrand'
import { IconSearchL } from 'icons/components/IconSearchL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useSearchLink } from 'links/search/hook/useSearchLink'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { ClientLink } from 'utils/link/client'

import { TEST_IDS } from '../../constants/landing'

type HeaderSearchBoxProps = {
	readonly className: string
	readonly labelClassName: string
	readonly iconClassName: string
}

export function HeaderSearchLink({
	className,
	labelClassName,
	iconClassName,
}: HeaderSearchBoxProps): React.ReactNode {
	const { t } = useLabels()
	const { brand } = useNavigationBrand()
	const { isLargeOrGreater } = useResponsive()
	const {
		country: { brands },
	} = useMasterData()
	const href = useSearchLink({ brandId: brand?.id ?? brands[0].id })
	const LinkComponent = isLargeOrGreater ? InspirationalClientLink : ClientLink
	const { iconColor, inspirationalVariant } = useTransparentProps()

	return (
		<LinkComponent
			className={className}
			href={href}
			data-testid={TEST_IDS.HEADER_SEARCH_LINK}
			linkProps={{ 'aria-label': t('accessibility.header.search.icon') }}
			variant={inspirationalVariant}
		>
			<IconSearchL className={iconClassName} color={iconColor} />
			<span className={labelClassName}>{t('header.search.text')}</span>
		</LinkComponent>
	)
}
