import { useMenus } from 'menu/hooks/useMenus'
import { useMenuService } from 'menu/services/menu/client'
import { hideInSections } from 'menu/utils/hideInSections'
import { useEffect } from 'react'

export function useMenuData(firstOpen: boolean) {
	const { data } = useMenuService(firstOpen)
	const { updateMenus } = useMenus()

	const menusData = hideInSections(data?.menus)

	useEffect(() => {
		updateMenus(menusData)
	}, [menusData])

	return { menusData }
}
