// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconLogoOutlet = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles['rotate-rtl'],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			aria-hidden={alt ? undefined : 'true'}
			className={computedClassName}
			data-testid='logo-outlet'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 464.6 52.3'
		>
			{alt && <title lang={lang}>{alt}</title>}
			<path d='m32.1 24.3 5 7.7 8.7-13.4V50h8.5V2.6h-8.6zM1.6 2.6V50H10V18.6l17.8 27.8 5-7.7L10.1 2.6zm84.2 0h-.2l12.1 24.8h-15l3.7-7.1-4.8-9.4L61.2 50h9.7l7.6-14.6H102l7.6 14.6h9.7L94.6 2.6zm77.8 22.5 8.5 8V2.6h-8.5zM125 2.6V50h8.5V15.4L165.7 50h6.5v-6L133.4 2.6zm86.1 7.2c6.6 0 12.8 2.4 16.6 6.3l6.4-5.9c-5.6-5.2-13.2-8.7-22.9-8.7-15.4 0-29.3 9.7-29.5 22.5h9.4c1.8-8.3 9.6-14.2 20-14.2m-.9 22.3h16.9v3.4c0 4-6 5.8-9.6 6.6-2.1.4-4.3.7-6.5.7h-.8c-8.8-.2-15.3-4.7-18.5-10.9h-9.4c3.1 11.6 14.8 19.1 28.4 19.1 3.4 0 6.7-.3 10-1.1 6.9-1.7 15.1-6.2 15.1-13.3V23.8h-25.7zm91.7-6.4c0-14.2-13.5-24.6-28.9-24.6h-.1c-15.4 0-28.9 10.4-28.9 24.6 0 12.9 10.9 23.3 24.8 25.2v-8.5c-9-2-15.8-8-15.8-16.2 0-9.5 8.7-16.9 19.8-16.9h.3c11.1 0 19.8 7.4 19.8 16.9 0 8.2-6.8 14.3-15.9 16.2v8.5c13.9-1.8 24.9-12.2 24.9-25.2m14.9 7c.5-1.6 1.4-3 2.4-4.2 1.1-1.2 2.4-2.2 3.9-2.8 3.3-1.4 7.1-1.4 10.4 0 1.5.7 2.8 1.6 3.9 2.8s1.9 2.7 2.4 4.2c.6 1.7.9 3.5.9 5.2s-.3 3.5-.9 5.1c-.5 1.5-1.4 2.9-2.4 4.2-1.1 1.2-2.4 2.1-3.9 2.8-3.4 1.3-7.1 1.3-10.5 0-1.5-.6-2.8-1.6-3.9-2.8q-1.65-1.8-2.4-4.2c-.6-1.7-.9-3.4-.8-5.1 0-1.7.3-3.5.9-5.2m5.1 8.4q.3 1.5 1.2 2.7c.5.8 1.3 1.5 2.1 1.9 1 .5 2.1.7 3.2.7s2.2-.2 3.2-.7c.8-.5 1.6-1.1 2.1-1.9q.9-1.2 1.2-2.7c.2-1 .4-2.1.4-3.1 0-1.1-.1-2.2-.4-3.2-.2-1-.6-1.9-1.2-2.8-.5-.8-1.3-1.5-2.1-1.9-1-.5-2.1-.7-3.2-.7s-2.2.2-3.2.7c-.9.5-1.6 1.1-2.1 1.9-.6.8-1 1.8-1.2 2.8-.3 1.1-.4 2.1-.4 3.2 0 1 .1 2 .4 3.1m43 7.4c-1.9 1.7-4.5 2.5-7.9 2.5s-6-.8-7.9-2.5c-1.9-1.6-2.8-4.2-2.8-7.6V25.2h5.6v15.7c0 .7.1 1.4.2 2s.4 1.2.7 1.7c.4.5 1 1 1.6 1.2.9.4 1.8.5 2.7.5 2 0 3.4-.4 4.1-1.4.7-.9 1.1-2.3 1.1-4.2V25.2h5.5v15.7c-.1 3.4-1 6-2.9 7.6m7.4-18.6v-4.7H393v4.7h-7.6v20.6h-5.5V29.9zm30.9-4.7v20.6h12.3v4.7h-17.8V25.2zm36 0v4.7h-13.3v5.4h12.2v4.3h-12.2v6.2h13.6v4.7h-19.1V25.2zm3.8 4.7v-4.7h20.7v4.7h-7.6v20.6h-5.5V29.9z' />
		</svg>
	)
}
