'use client'

import { Input } from 'fukku/Input'
import type { InputProps } from 'fukku/Input/types'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { Controller, useFormContext } from 'react-hook-form'

type EmailInputProps = {
	readonly name: NonNullable<InputProps['name']>
	readonly id: InputProps['id']
	readonly rules: {
		maxLength: number
		regex: string
	}
} & Partial<InputProps>

export function EmailInput({
	name: controllerName,
	id,
	rules: { regex, maxLength },
	...inputProps
}: EmailInputProps): React.ReactNode {
	const { t } = useLabels()
	const { control } = useFormContext()

	return (
		<Controller
			name={controllerName}
			control={control}
			rules={{
				required: { value: true, message: t('form.empty.errormessage') },
				pattern: {
					value: new RegExp(regex),
					message: t('form.email.format.errormessage'),
				},
				maxLength: {
					value: maxLength,
					message: t('form.email.format.errormessage'),
				},
			}}
			render={({
				field: { name, onBlur, onChange, value, ref },
				fieldState: { error },
			}) => (
				<Input
					{...inputProps}
					id={id}
					errorMessage={error ? error.message : undefined}
					label={t('form.email.label.text')}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					type='text'
					value={value}
					refCallback={ref}
				/>
			)}
		/>
	)
}
