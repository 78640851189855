'use client'

import { BUTTON_INSPIRATIONAL_VARIANT } from '@fukku/button/Inspirational/constants'
import { useCartDispatch, useCartState } from 'cart/context/CartProvider'
import { useTransparentProps } from 'header-utils/context/TransparentPropsContext/useTransparentProps'
import { IconBagL } from 'icons/components/IconBagL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { CartClientLink } from 'links/cart/client/CartClientLink'
import dynamic from 'next/dynamic'
import type { ReactNode } from 'react'

import { TotalItems } from '../TotalItems/TotalItems'

import styles from './ShoppingCartButton.module.scss'

const LimitsModal = dynamic(() =>
	import('../../../components/LimitsModal/LimitsModal').then(
		(module) => module.LimitsModal
	)
)

const AddNoStockModal = dynamic(() =>
	import('../../../components/AddNoStockModal/AddNoStockModal').then(
		(module) => module.AddNoStockModal
	)
)

export const ShoppingCartSmall = (): ReactNode => {
	const { t } = useLabels()
	const dispatch = useCartDispatch()
	const { iconColor, inspirationalVariant } = useTransparentProps()
	const isInverse =
		inspirationalVariant === BUTTON_INSPIRATIONAL_VARIANT.INVERSE

	const { openedLimitWarningModal, openedAddNoStockModal } = useCartState()

	const handleClick = (): void => {
		dispatch({ showShoppingCart: true })
	}

	return (
		<div className={styles.userIconContainer}>
			<CartClientLink
				linkProps={{
					rel: 'nofollow',
				}}
				className={styles.userIcon}
				onClick={handleClick}
				id='header.userMenu.cart.button'
				data-testid={'header.userMenu.cart.button'}
			>
				<span className={styles.iconWrapper}>
					<IconBagL
						className={styles.icon}
						alt={t('shoppingBag.empty.title')}
						color={iconColor}
					/>
					<TotalItems withLabel={false} icon inverse={isInverse} />
				</span>
			</CartClientLink>

			{openedLimitWarningModal && <LimitsModal />}
			{openedAddNoStockModal && <AddNoStockModal />}
		</div>
	)
}
