export enum DataTestIds {
	IconLarge = 'header.userMenu.login_any',
	IconSmall = 'header.userMenu.login_mobile_any',
	MenuWrapper = 'header.accountMenu.wrapper',
	MenuContent = 'header.accountmenu.content',
	LoginButton = 'header.myAccount.login.button',
	RegisterLink = 'header.usermenu.register.click',
	ItemMyAccount = 'header.userSubmenu.my_account',
	ItemMyPurchases = 'header.userSubmenu.my_purchases',
	ItemMangoLikesYou = 'header.userSubmenu.mango_likes_you',
	ItemHelp = 'header.userSubmenu.help',
	ItemLogout = 'header.userSubmenu.logout',
}
