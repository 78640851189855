'use client'

import { ChatbotStorage } from 'oldchatbot/types/ChatbotStorage'
import {
	getFromLocalStorage,
	removeFromLocalStorage,
	setToLocalStorage,
} from 'utils/webStorage/localStorage'

import { expirationTime } from '../../constants/constants'

export type ChatbotLocalStorage = {
	expDate: number
	value: boolean
}

export const useChatbotStorage = () => {
	const removeChatbotStorage = () => {
		removeFromLocalStorage(ChatbotStorage.CHAT_OPEN)
	}

	const getIsChatbotOpenStorage = () => {
		const storageObject = getFromLocalStorage<ChatbotLocalStorage>(
			ChatbotStorage.CHAT_OPEN
		)

		if (storageObject && new Date().getTime() < storageObject.expDate) {
			return storageObject.value
		}

		removeChatbotStorage()
		return false
	}

	const saveIsChatbotOpenStorage = () => {
		const expDate = new Date().getTime() + expirationTime
		setToLocalStorage(ChatbotStorage.CHAT_OPEN, { expDate, value: true })
	}

	const isConversationalIris = () =>
		!!getFromLocalStorage(ChatbotStorage.IS_CONVERSATIONAL_IRIS)

	return {
		removeChatbotStorage,
		saveIsChatbotOpenStorage,
		getIsChatbotOpenStorage,
		isConversationalIris,
	}
}
