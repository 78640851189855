'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { Domains } from 'monitoring/constants/domains'
import { useLog } from 'monitoring/sendLog/client/useLog'

import { action as actionEvent } from '../../../components/CIAM/events/action/action'
import { auth as authEvent } from '../../../components/CIAM/events/auth/auth'
import { clear as clearEvent } from '../../../components/CIAM/events/clear/clear'
import { initialize as initializeEvent } from '../../../components/CIAM/events/initialize/initialize'
import { getRecommendation } from '../../../services/CIAM/getRecommendation'
import type { ActionTypes, FeatureFlag } from '../../../types/CIAM'

export function useCIAM() {
	const {
		enabled,
		variables: { isDRSEnabled, isOldSDK },
	} = useFeatureFlag<FeatureFlag>(featureFlagKeys.CIAM)
	const { sendLog } = useLog()

	const action = async (type: ActionTypes) => {
		if (enabled && isDRSEnabled) {
			try {
				const actionToken = await actionEvent(type, isOldSDK)
				await getRecommendation(actionToken)
			} catch {
				sendLog({ domain: Domains.CIAM, message: `[${type}] DRS Fail` })
			}
		}
	}

	const auth = () => {
		if (enabled) {
			authEvent(isOldSDK)
		}
	}

	const clear = () => {
		if (enabled) {
			clearEvent(isOldSDK)
		}
	}

	const initialize = () => {
		if (enabled) {
			initializeEvent(isOldSDK)
		}
	}

	return { action, auth, clear, enabled, initialize, isOldSDK }
}
