import { brandPath } from 'country-configuration/utils/brands/brandPath'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useMenus } from 'menu/hooks/useMenus'
import { Responsive } from 'responsive/components/Responsive/Responsive'

import { Brands } from '../Brands/Brands'
import { HamburgerButton } from '../HamburgerButton/HamburgerButton'

export const OpenMenu = () => {
	const { open, updateOpen } = useMenus()
	const {
		country: { brands: countryBrands, countryISO, languageISO },
	} = useMasterData()
	const href = `/${countryISO.toLocaleLowerCase()}/${languageISO}/h`
	const { t } = useLabels()

	const toggleOpen = () => {
		updateOpen(!open)
	}

	const brands = countryBrands.map((brand) => {
		return {
			...brand,
			url: brandPath({
				brandId: brand.id,
				brands: countryBrands,
				translate: t,
				initialPath: href,
			}),
		}
	})

	return (
		<>
			<Responsive onLessThanLarge>
				<HamburgerButton handleOpenMenu={toggleOpen} />
			</Responsive>
			<Responsive onLargeOrGreater>
				<Brands brands={brands} handleOpenMenu={toggleOpen} />
			</Responsive>
		</>
	)
}
