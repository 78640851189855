import { BUTTON_VARIANT } from '../Button.types'

import text from '@fukku/styles/texts.module.scss'
import styles from './ButtonInspirational.module.scss'

export enum LINK_SIZE {
	S = 'small',
	M = 'medium',
}

export const linkInspirationalSizeStyles: Record<LINK_SIZE, string> = {
	[LINK_SIZE.S]: text.uppercaseS,
	[LINK_SIZE.M]: text.uppercaseM,
}

export enum BUTTON_INSPIRATIONAL_STATE {
	DEFAULT = 'default',
	ACTIVE = 'active',
	UNSELECTED = 'unselected',
	DISABLED = 'disabled',
}

export const stateStyles: Record<BUTTON_INSPIRATIONAL_STATE, string> = {
	[BUTTON_INSPIRATIONAL_STATE.ACTIVE]: styles.active,
	[BUTTON_INSPIRATIONAL_STATE.UNSELECTED]: styles.unselected,
	[BUTTON_INSPIRATIONAL_STATE.DISABLED]: styles.disabled,
	[BUTTON_INSPIRATIONAL_STATE.DEFAULT]: '',
}

export enum BUTTON_INSPIRATIONAL_VARIANT {
	// biome-ignore lint/style/useLiteralEnumMembers: TODO: Fix this
	DEFAULT = BUTTON_VARIANT.DEFAULT,
	// biome-ignore lint/style/useLiteralEnumMembers: TODO: Fix this
	INVERSE = BUTTON_VARIANT.INVERSE,
	PROMOTION = 'promotion',
}
