'use client'

import { useHeaderState } from 'header-utils/context/HeaderContext/useHeaderState'

import styles from './HeaderLogo.module.scss'

type LogoElementTagProps = {
	readonly children: React.ReactNode
	readonly isMainHeading?: boolean
}

export function LogoElementTag({
	isMainHeading,
	children,
}: LogoElementTagProps) {
	const LogoComponent = isMainHeading ? 'h1' : 'div'
	const { isSearchOpen } = useHeaderState()

	if (isSearchOpen) {
		return null
	}

	return <LogoComponent className={styles.logo}>{children}</LogoComponent>
}
