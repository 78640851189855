import { useContext } from 'react'

import { TransparentPropsContext } from './TransparentPropsContext'

export const useTransparentProps = () => {
	const context = useContext(TransparentPropsContext)

	if (!context) {
		throw new Error(
			'useTransparentProps must be used within a TransparentPropsContext'
		)
	}

	return context
}
