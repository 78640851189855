'use client'

import dynamic from 'next/dynamic'

import { useDismissBanner } from '../hooks/useDismissBanner'
import { useShowBanner } from '../hooks/useShowBanner'

const BannerWrapperDynamic = dynamic(() =>
	import('./BannerWrapper/BannerWrapper').then((mod) => mod.BannerWrapper)
)

export const BannerApp = () => {
	const { showBanner } = useShowBanner()
	const { isDismissed, closeBanner } = useDismissBanner()

	if (!showBanner) {
		return null
	}

	return (
		<BannerWrapperDynamic closeAction={closeBanner} isDismissed={isDismissed} />
	)
}
