'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect, useState } from 'react'
import { PageType } from 'types/analytics'

import { isAndroidAgent } from '../utils/getClientEnviroment'
import { useDismissBanner } from './useDismissBanner'

export function useShowBanner() {
	const [showBanner, setShowBanner] = useState(false)
	const { isShop, isMobile, pageType: currentPageType } = useMasterData()
	const { cookiesConsent } = useCookiesContext()
	const { isDismissed } = useDismissBanner()

	const isAndroid = isAndroidAgent(isMobile)

	const isCorrectPage =
		currentPageType !== PageType.ShoppingCart &&
		currentPageType !== PageType.ConfirmationPage

	const userHasInteractedWithCookiesBanner = cookiesConsent !== undefined

	const deviceWebCriteria = isShop && isAndroid && isCorrectPage
	const userCriteria = !isDismissed && userHasInteractedWithCookiesBanner

	useEffect(() => {
		if (deviceWebCriteria && userCriteria) {
			setShowBanner(true)
		}
	}, [
		isShop,
		isAndroid,
		isCorrectPage,
		isDismissed,
		userHasInteractedWithCookiesBanner,
	])

	return { showBanner }
}
