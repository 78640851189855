'use client'

import dynamic from 'next/dynamic'

import { ChatbotContainer } from 'oldchatbot/components/ChatbotContainer/ChatbotContainer'

const DynamicChatbotProvider = dynamic(
	() =>
		import('chatbot/contexts/ChatbotProvider').then(
			(mod) => mod.ChatbotProvider
		),
	{ ssr: false }
)

const DynamicChatbot = dynamic(
	() => import('chatbot/components/Chatbot/Chatbot').then((mod) => mod.Chatbot),
	{ ssr: false }
)

export function ChatbotWrapper({
	isNewChatbot,
}: { readonly isNewChatbot: boolean | undefined }) {
	return isNewChatbot ? (
		<DynamicChatbotProvider>
			<DynamicChatbot />
		</DynamicChatbotProvider>
	) : (
		<ChatbotContainer />
	)
}
