'use client'

import { useAbTest } from 'ab-test/hooks/useAbTest/useAbTest'
import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { useSearchLink } from 'links/search/hook/useSearchLink'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect } from 'react'
import { AbTestKeys } from 'types/constants/AbTestKeys'

export const InitAbTest = ({
	children,
}: {
	readonly children: JSX.Element | JSX.Element[]
}) => {
	const { navigate } = useNavigation()

	const { brand, country } = useMasterData()
	const searchHref = useSearchLink({ brandId: brand ?? country.brands[0].id })

	const newSearchUi = useAbTest({
		key: AbTestKeys.NewSearchUi,
		defaultDecision: {
			enabled: false,
		},
	})

	const newSearchUiEnabled = newSearchUi?.enabled

	useEffect(() => {
		if (newSearchUiEnabled) {
			navigate(searchHref)
		}
	}, [newSearchUiEnabled, searchHref, navigate])

	return newSearchUiEnabled ? null : children
}
