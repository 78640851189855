import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useEffect, useState } from 'react'

import { TIMEOUT_LABELS_READY } from '../constants/timeOuts'

export const useIsLabelReady = (label: string) => {
	const [isReady, setIsReady] = useState(false)
	const { t } = useLabels()

	useEffect(() => {
		if (label === '') {
			return () => {}
		}

		const timeout = setTimeout(() => {
			setIsReady(true)
		}, TIMEOUT_LABELS_READY)

		if (t(label) !== label) {
			clearTimeout(timeout)
			setIsReady(true)
		}

		return () => {
			clearTimeout(timeout)
		}
	}, [label, t])

	return isReady
}
