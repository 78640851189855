'use client'

import { useEffect, useState } from 'react'

export const BANNER_DISMISSAL_KEY = 'isBannerDismissed'

export const useDismissBanner = () => {
	const [isDismissed, setIsDismissed] = useState(false)

	useEffect(() => {
		const isDismissedValue = Boolean(
			sessionStorage.getItem(BANNER_DISMISSAL_KEY)
		)
		setIsDismissed(isDismissedValue)
	}, [])

	const closeBanner = () => {
		sessionStorage.setItem(BANNER_DISMISSAL_KEY, 'true')
		setIsDismissed(true)
	}

	return { isDismissed, closeBanner }
}
