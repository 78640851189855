import {
	MIN_WIDTH as MIN_WIDTH_STRUCTURE,
	PADDING_STRUCTURE,
} from '../constants/structureSizes'

import stylesCloseButton from 'menu/components/CloseButton/CloseButton.module.scss'

export function setMinWidthForStructure(
	current: HTMLUListElement,
	open: boolean
) {
	const closeButtonWidth = Number.parseInt(
		stylesCloseButton.CLOSE_BUTTON_WIDTH,
		10
	)
	const element = document.getElementById('wrapperMenu')

	if (!open && current) {
		const brandsWidth = current.offsetWidth
		const topBarWidth = brandsWidth + PADDING_STRUCTURE + closeButtonWidth
		const minWidth =
			topBarWidth < MIN_WIDTH_STRUCTURE ? MIN_WIDTH_STRUCTURE : topBarWidth

		element?.style.setProperty('--structure-min-width', `${minWidth}px`)
	}
}

export function getPropertyStructureMinWidth() {
	const element = document.getElementById('wrapperMenu')

	if (!element) {
		return 0
	}

	const getValue = getComputedStyle(element).getPropertyValue(
		'--structure-min-width'
	)

	return Number.parseInt(getValue, 10) || 0
}
