'use client'

import { ButtonPrimary } from '@fukku/button/Primary'
import { ButtonSecondary } from '@fukku/button/Secondary'
import { useLabels } from 'labels/hooks/useLabels/useLabels'

import { TestIds } from '../../../constants'

import text from 'fukku/styles/texts.module.scss'
import styles from './GenericError.module.scss'

type ErrorBodyProps = {
	retryHandler(): Promise<void>
	cancelHandler(): void
}
export const ErrorBody = ({
	cancelHandler,
	retryHandler,
}: ErrorBodyProps): React.ReactNode => {
	const { t } = useLabels()

	return (
		<div className={styles.genericError}>
			<h1 className={`${text.titleXL} ${styles.errorTitle}`}>
				{t('error.connection.title')}
			</h1>
			<p className={styles.errorText}>
				{t('error.connection.generic.bodycopy')}
			</p>
			<ButtonPrimary
				className={styles.errorActionButton}
				data-testid={TestIds.RetryButton}
				onClick={retryHandler}
				fullWidth
				type='submit'
			>
				{t('error.connection.retry.button')}
			</ButtonPrimary>
			<ButtonSecondary
				className={styles.errorActionButton}
				data-testid={TestIds.CancelButton}
				onClick={cancelHandler}
				fullWidth
			>
				{t('error.connection.cancel.button')}
			</ButtonSecondary>
		</div>
	)
}
