'use client'

import { brandPath } from 'country-configuration/utils/brands/brandPath'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import { useAbTest } from 'ab-test/hooks/useAbTest/useAbTest'
import { AbTestKeys } from 'types/constants/AbTestKeys'
import { url } from '../constants'
import type { SearchLinkDependencies } from '../types'
import { searchUrl } from '../utils/searchUrl'

export const useSearchLink = ({
	brandId,
	searchTerm,
}: SearchLinkDependencies) => {
	const { country } = useMasterData()
	const { t } = useLabels()
	const newSearchTest = useAbTest({
		key: AbTestKeys.NewSearchUi,
	})

	const urlParams = {
		brandPath: brandPath({
			brandId,
			brands: country.brands,
			translate: t,
		}),
		pathNameBase: url,
		isNew: newSearchTest?.enabled,
		searchTerm,
	}

	return searchUrl(urlParams)
}
