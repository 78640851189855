'use client'

import { FieldValidationsContext } from 'country-configuration/contexts/FieldValidationsProvider'
import type { CountryFieldValidationsResponse } from 'country-configuration/types/FieldValidations.type'
import { useContext } from 'react'

export const useFieldValidations = (): CountryFieldValidationsResponse => {
	const fieldValidation = useContext(FieldValidationsContext)
	if (!fieldValidation) {
		throw new Error(
			`[FieldValidationsProvider hook] - useFieldValidations must be used within a <FieldValidationsProvider>. 
			<FieldValidationsProviderWrapper> may be useful, it internally fetch country-field-validations and places <FieldValidationsProvider> wrapping all children.`
		)
	}
	return fieldValidation
}
