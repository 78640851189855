'use client'

import { Logo } from 'fukku/Logo'
import { useTransparentProps } from 'header-utils/context/TransparentPropsContext/useTransparentProps'

import styles from './MangoLogo.module.scss'

export function MangoLogo() {
	const { iconColor } = useTransparentProps()

	return <Logo className={styles.logoImg} color={iconColor} />
}
