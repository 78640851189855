import { ShopBrand } from 'types/brands'

import type { FormValues } from '../types/form'

export enum FormFieldNames {
	Email = 'email',
	Root = 'root',
	LegalConfirmationCheckbox = 'legal-confirmation',
}

export enum FormFieldIds {
	Email = 'newsletter-form-email-input',
	SubmitButton = 'newsletter-form-submit-button',
	LegalConfirmation = 'newsletter-form-legal-confirmation-checkbox',
}

export const defaultValues: FormValues = {
	[FormFieldNames.Email]: '',
	[FormFieldNames.LegalConfirmationCheckbox]: false,
}

export enum TestIds {
	EmailInput = 'newsletter.subscription.emailInput.text',
	SubmitButton = 'newsletter.subscription.submitButton.subscribe',
	RetryButton = 'newsletter.subscription.genericError.retrySubmit',
	CancelButton = 'newsletter.subscription.genericError.emptyFields',
	LegalConfirmation = 'newsletter.subscription.legalConfirmation.checkbox',
	DoubleOptinContainter = 'newsletter.subscription.doubleOptinFeedback.container',
	DoubleOptinButton = 'newsletter.subscription.doubleOptinFeedback.confirmation',
}

export enum NewsletterOrigin {
	FOOTER = 'FOOTER',
	LANDING = 'LANDING',
}

export enum NewsletterAnalytics {
	NEWSLETTER_NATIVE = 'native',
	NEWSLETTER_SHOW = 'newsletter_show',
	NEWSLETTER_SUBSCRIBE = 'newsletter_subscribe',
}

export enum ServiceErrorCodes {
	Fields = 400,
	Generic = 500,
}

// TODO: handle outlet
export const DEFAULT_SUBSCRIPTION_BRAND = ShopBrand.she
export const FEEDBACK_ID = 'subscriptionSuccessNotification'
