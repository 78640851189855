'use client'

import { useBagTotalItems } from 'cart/hooks/queries/useBagTotalItems'
import { useAnalytics } from 'cart/hooks/useAnalytics'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import { useEffect, useRef } from 'react'
import { cx } from 'utils/cx'

import styles from './TotalItems.module.scss'

interface Props {
	readonly withLabel?: boolean
	readonly manageFocus?: (itemRemoved: boolean) => void
	readonly icon?: boolean
	readonly inverse?: boolean
}

export const TotalItems = ({
	withLabel,
	manageFocus,
	icon,
	inverse = false,
}: Props) => {
	const { t } = useLabels()
	const { sendBagErrorTotalItemsEvent } = useAnalytics()
	const { totalItems } = useBagTotalItems({
		errorCallback: sendBagErrorTotalItemsEvent,
	})

	const numItems = useRef<number | null>(null)

	useEffect(() => {
		if (totalItems && manageFocus) {
			if (numItems.current) {
				const itemRemoved = numItems.current > totalItems
				manageFocus(itemRemoved)
			}
			numItems.current = totalItems
		}
	}, [totalItems, manageFocus])

	const buttonClassName = withLabel ? styles.large : styles.small

	const headerCartText: string =
		totalItems === undefined
			? ''
			: replacePlaceholders(t('header.shoppingBag.placeholder'), {
					1: totalItems || 0,
				})

	return (
		<span
			data-testid='bag.header.totalItems'
			className={cx(buttonClassName, inverse && styles.inverse)}
		>
			{icon ? totalItems : headerCartText}
		</span>
	)
}
