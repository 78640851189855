'use client'

import { useFieldValidations } from 'country-configuration/services/client/useFieldValidations'
import { useForm } from 'form-components'
import { EmailInput } from 'form-components/components/EmailInput/EmailInput'
import { SubmitButton } from 'form-components/components/SubmitButton/SubmitButton'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { NEWSLETTER_POLICY_ID } from 'legal/constants/privacyPolicy'
import dynamic from 'next/dynamic'
import { type ReactNode, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { cx } from 'utils/cx'

import {
	FormFieldIds,
	FormFieldNames,
	type NewsletterOrigin,
	TestIds,
	defaultValues,
} from '../../constants'
import { useSubscribe } from '../../hooks/useSubscribe'
import type { FormValues } from '../../types/form'
import { GenericError } from './GenericError/GenericError'

import styles from './Form.module.scss'

const FooterLegalConfirmationDynamic = dynamic(() =>
	import('./FooterLegalConfirmation/FooterLegalConfirmation').then(
		(module) => module.FooterLegalConfirmation
	)
)

const FeedbackDynamic = dynamic(() =>
	import('../Feedback/Feedback').then((module) => module.Feedback)
)

type FormProps = {
	readonly className?: string
	readonly origin: NewsletterOrigin
	readonly legalConfirmation?: boolean
}

export function Form({
	className,
	origin,
	legalConfirmation,
}: FormProps): ReactNode {
	const { t } = useLabels()
	const { subscribe, subscribeError, subscribeSuccess, resetSubscription } =
		useSubscribe(origin)

	const formMethods = useForm({
		defaultValues,
		mode: 'onTouched',
	})
	const { handleSubmit, setError, reset } = formMethods

	const { email } = useFieldValidations()

	const onSubmit = (values: FormValues): Promise<void> =>
		subscribe(values[FormFieldNames.Email])

	useEffect(() => {
		if (subscribeError) {
			setError(subscribeError, {
				type: 'validate',
				message: t(`form.${subscribeError}.format.errormessage`),
			})
		}
	}, [subscribeError])

	useEffect(() => {
		if (subscribeSuccess) {
			reset()
		}
	}, [subscribeSuccess])

	return (
		<FormProvider {...formMethods}>
			<form
				className={cx(styles.form, className)}
				onSubmit={handleSubmit(onSubmit)}
			>
				{legalConfirmation && <FooterLegalConfirmationDynamic />}
				<div className={styles.inputContainer}>
					<EmailInput
						name={FormFieldNames.Email}
						id={FormFieldIds.Email}
						rules={email}
						data-testid={TestIds.EmailInput}
					/>
					<SubmitButton
						id={FormFieldIds.SubmitButton}
						data-testid={TestIds.SubmitButton}
						aria-describedby={NEWSLETTER_POLICY_ID}
						className={styles.submitButton}
						secondary
					>
						{t('footer.subscribe.button')}
					</SubmitButton>
				</div>
				<GenericError submitHandler={onSubmit} resetValues={reset} />
			</form>
			<FeedbackDynamic
				sendSuccessFeedback={!!subscribeSuccess}
				onFeedbackSent={resetSubscription}
			/>
		</FormProvider>
	)
}
