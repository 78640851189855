import { useCartDispatch, useCartState } from 'cart/context/CartProvider'
import {
	type RefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { useSession } from 'session/src/hooks/useSession'
import { Keyboard } from 'types/keyboard'

import { sendDisplayMenuEvent } from '../utils/analytics'

const CLOSE_TIMEOUT = 100

const CLOSING_CART_ANIMATION_TIMEOUT = 300

type UseAccountActionsProps = {
	linkRef: RefObject<HTMLAnchorElement>
	menuRef: RefObject<HTMLDivElement>
}

export const useAccountActions = ({
	linkRef,
	menuRef,
}: UseAccountActionsProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const { logged } = useSession()
	const { isLargeOrGreater } = useResponsive()
	const cartDispatch = useCartDispatch()
	const { showPreview: showCartPreview } = useCartState()

	const isLinkHovered = useRef(false)
	const isMenuHovered = useRef(false)

	const open = useCallback(() => {
		if (isOpen) {
			return
		}

		sendDisplayMenuEvent()

		if (showCartPreview) {
			cartDispatch({ showPreview: false })
			setTimeout(() => {
				setIsOpen(true)
			}, CLOSING_CART_ANIMATION_TIMEOUT)
		} else {
			setIsOpen(true)
		}
	}, [isOpen, showCartPreview, cartDispatch])

	// biome-ignore lint/correctness/useExhaustiveDependencies: it's necessary to add both logged and isLargeOrGreater dependencies to force a rerender if the device size or the user session changes
	const isNotHovered = useCallback(
		() => !(isMenuHovered.current || isLinkHovered.current),
		[isMenuHovered, isLinkHovered, logged, isLargeOrGreater]
	)

	const close = useCallback(() => {
		if (isNotHovered()) {
			setTimeout(() => {
				if (isNotHovered()) {
					setIsOpen(false)
				}
			}, CLOSE_TIMEOUT)
		}
	}, [isNotHovered])

	const isTargetFocusInMenu = useCallback(
		(target?: EventTarget | null) =>
			target && menuRef.current?.contains(target as Node),
		[menuRef]
	)

	const onLinkEnter = () => {
		open()
		isLinkHovered.current = true
	}

	const onLinkLeave = () => {
		isLinkHovered.current = false
		close()
	}

	const onMenuEnter = () => {
		open()
		isMenuHovered.current = true
	}

	const onMenuLeave = () => {
		isMenuHovered.current = false
		close()
	}

	const onMenuFocusOut = useCallback(
		(event: FocusEvent) => {
			const target = event?.relatedTarget

			if (!isTargetFocusInMenu(target)) {
				setIsOpen(false)
			}
		},
		[isTargetFocusInMenu]
	)

	const onEscapeKeyPress = useCallback((e: KeyboardEvent) => {
		if (e.key === Keyboard.Escape) {
			setIsOpen(false)
		}
	}, [])

	useEffect(() => {
		if (isLargeOrGreater) {
			linkRef.current?.addEventListener('mouseenter', onLinkEnter)
			linkRef.current?.addEventListener('mouseleave', onLinkLeave)
			menuRef.current?.addEventListener('mouseenter', onMenuEnter)
			menuRef.current?.addEventListener('mouseleave', onMenuLeave)
			menuRef.current?.addEventListener('focusout', onMenuFocusOut)
			menuRef.current?.addEventListener('keydown', onEscapeKeyPress)
		}

		return () => {
			linkRef.current?.removeEventListener('mouseenter', onLinkEnter)
			linkRef.current?.removeEventListener('mouseleave', onLinkLeave)
			menuRef.current?.removeEventListener('mouseleave', onMenuLeave)
			menuRef.current?.removeEventListener('mouseenter', onMenuEnter)
			menuRef.current?.removeEventListener('focusout', onMenuFocusOut)
			menuRef.current?.removeEventListener('keydown', onEscapeKeyPress)
		}
	}, [
		open,
		close,
		isNotHovered,
		onMenuFocusOut,
		onEscapeKeyPress,
		isLargeOrGreater,
	])

	return useMemo(
		() => ({
			isOpen,
			open,
			close,
		}),
		[isOpen, open, close]
	)
}
