'use client'

import dynamic from 'next/dynamic'
import { useRef } from 'react'

import { AccountIcon } from '../components/AccountIcon/AccountIcon'
import { ACCOUNT_MENU_ID } from '../constants/accountMenu'
import { DataTestIds } from '../constants/testIds'
import { useAccountActions } from '../hooks/useAccountActions'
import { useMenuPosition } from '../hooks/useMenuPosition'

import styles from './AccountActions.module.scss'

const AccountMenu = dynamic(() =>
	import('../components/AccountMenu/AccountMenu').then((mod) => mod.AccountMenu)
)

export const AccountActions = ({
	hasFavorites,
}: Readonly<{ hasFavorites: boolean }>) => {
	const linkRef = useRef<HTMLAnchorElement>(null)
	const menuRef = useRef<HTMLDivElement>(null)
	const arrowRef = useRef<HTMLDivElement>(null)

	const { isOpen, open } = useAccountActions({
		linkRef,
		menuRef,
	})

	const { repositionMenu } = useMenuPosition({
		isOpen,
		hasFavorites,
		linkRef,
		menuRef,
		arrowRef,
	})

	return (
		<>
			<AccountIcon ref={linkRef} isExpanded={isOpen} onClick={open} />
			<div
				id={ACCOUNT_MENU_ID}
				className={styles.menuWrapper}
				ref={menuRef}
				data-testid={DataTestIds.MenuWrapper}
			>
				{isOpen ? (
					<AccountMenu arrowRef={arrowRef} repositionMenu={repositionMenu} />
				) : null}
			</div>
		</>
	)
}
