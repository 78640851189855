'use client'

import { useDocumentDirection } from 'hooks/useDocumentDirection/useDocumentDirection'
import { useMenus } from 'menu/hooks/useMenus'
import { useRef } from 'react'

import styles from './BrandsUnderline.module.scss'

export const BrandsUnderline = () => {
	const ref = useRef<HTMLSpanElement>(null)
	const {
		open,
		refs: { refBrands },
		brandSelected,
		getTopBrands,
	} = useMenus()
	const { isRtl } = useDocumentDirection()
	const firstOpen = useRef(true)
	const index = getTopBrands().findIndex((brand) => brand.id === brandSelected)

	const underlineDraw = (
		entry: HTMLLIElement,
		underlineRef: React.RefObject<HTMLSpanElement>
	) => {
		const entryBrand = entry.firstElementChild as HTMLElement
		const firstChild = entryBrand.firstChild as HTMLElement
		const { width = 0, left, right } = firstChild.getBoundingClientRect()
		const position = isRtl ? `calc(100vw - ${right}px)` : `${left}px`

		if (underlineRef?.current) {
			const underlineStyles = {
				transitionDuration: firstOpen.current ? '0s' : '',
				width: `${width}px`,
				insetInlineStart: position,
			}

			Object.assign(underlineRef.current.style, underlineStyles)
		}
	}

	const brandLi = refBrands.current?.childNodes[index] as HTMLLIElement

	if (brandLi && ref?.current && open) {
		underlineDraw(brandLi, ref)
		firstOpen.current = false
	}

	if (!open) {
		firstOpen.current = true
	}

	return <span ref={ref} className={styles.brandsListUnderline} />
}
