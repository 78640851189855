import { ButtonBase } from '@fukku/button/ButtonBase'
import { BUTTON_INSPIRATIONAL_VARIANT } from '@fukku/button/Inspirational/constants'
import { applyStylesIf, cx } from 'utils/cx'

import text from 'fukku/styles/texts.module.scss'
import styles from './LineAnimatedButton.module.scss'

interface LineAnimatedButtonProps extends React.ComponentProps<'button'> {
	readonly variant?: BUTTON_INSPIRATIONAL_VARIANT
	readonly active?: boolean
	readonly isTopBar?: boolean
}

export function LineAnimatedButton(
	props: LineAnimatedButtonProps
): React.ReactNode {
	const {
		children,
		disabled,
		active,
		variant = BUTTON_INSPIRATIONAL_VARIANT.DEFAULT,
		className,
		isTopBar = false,
		...rest
	} = props

	return (
		<ButtonBase
			{...rest}
			className={cx(
				className,
				styles[variant],
				text.uppercaseM,
				applyStylesIf(active === true && !disabled, styles.active),
				applyStylesIf(active === false && !disabled, styles.unselected),
				applyStylesIf(isTopBar, styles.topBar)
			)}
			disabled={disabled}
		>
			<span className={styles.text}>{children}</span>
		</ButtonBase>
	)
}
