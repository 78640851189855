import {
	CommonGenericEvents,
	SECTION_PARAMETERS_MY_ACCOUNT,
	SubSectionParameters,
} from 'account-actions/constants/analytics'
import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'

const sendSubsectionClickEvent = (subsection: string) => {
	sendGenericEvent(CommonGenericEvents.CLICK_HEADER, {
		subsection,
	})
}

export const sendLoginButtonClickEvent = () => {
	sendSubsectionClickEvent(SubSectionParameters.LOGIN)
}

export const sendLoyaltyLinkClickEvent = () => {
	sendSubsectionClickEvent(SubSectionParameters.LOYALTY)
}

export const sendMyPurchasesLinkClickEvent = () => {
	sendSubsectionClickEvent(SubSectionParameters.MY_PURCHASES)
}

export const sendHelpLinkClickEvent = () => {
	sendSubsectionClickEvent(SubSectionParameters.HELP)
}

export const sendRegisterLinkClickEvent = () => {
	sendSubsectionClickEvent(SubSectionParameters.REGISTER)
}

export const sendLogoutClickEvent = () => {
	sendSubsectionClickEvent(SubSectionParameters.LOGOUT)
}

export const sendDisplayMenuEvent = () => {
	sendGenericEvent(CommonGenericEvents.DISPLAY_HEADER, {
		section: SECTION_PARAMETERS_MY_ACCOUNT,
	})
}
