import { useEffect, useState } from 'react'

/**
 * Gets document direction and returns if it is right to left
 * - when isRtl is true all elements into the document should be showed from right to left
 * and direction methods like arrows or slides should be inverted
 * 
 * How to use:
 ```ts
 import { useDocumentDirection } from 'hooks/useDocumentDirection/useDocumentDirection'
 ...
 const { isRtl } = useDocumentDirection()
```
 * How to mock:
```ts
const mocks = vi.hoisted(() => ({
	mockIsRtl: false,
}))
...
vi.mock('hooks/useDocumentDirection/useDocumentDirection', () => ({
	useDocumentDirection: vi.fn(() => ({
		isRtl: mocks.mockIsRtl
	})),
}))
```
And then you can change the value of `mockIsRtl` to test the component in rtl mode
 */
export function useDocumentDirection() {
	const [textDirection, setTextDirection] = useState(
		typeof document !== 'undefined' ? document?.documentElement.dir : 'ltr'
	)
	useEffect(() => {
		setTextDirection(document.documentElement.dir)
	}, [])

	return {
		isRtl: textDirection === 'rtl',
	}
}
