import { type RefObject, useCallback } from 'react'

const HALF = 2
const DECIMAL = 10

const MENU_WIDTH = 398
const HALF_MENU_WIDTH = MENU_WIDTH / HALF
const DEFAULT_MENU_MARGIN_RIGHT = 32

const ICON_WIDTH = 78
const HALF_ICON_WIDTH = ICON_WIDTH / HALF

const LEFT_STYLE_PROPERTY = 'left'

type UseMenuPositionProps = {
	isOpen: boolean
	hasFavorites: boolean
	menuRef: RefObject<HTMLDivElement>
	arrowRef: RefObject<HTMLDivElement>
	linkRef: RefObject<HTMLAnchorElement>
}

export const useMenuPosition = ({
	isOpen,
	hasFavorites,
	linkRef,
	menuRef,
	arrowRef,
}: UseMenuPositionProps) => {
	const repositionMenu = useCallback(() => {
		const doRefsExist = menuRef.current && arrowRef.current && linkRef.current

		if (!isOpen || hasFavorites || !doRefsExist) {
			return
		}

		const menuBoundingBox = menuRef.current.getBoundingClientRect()
		const linkBoundingBox = linkRef.current.getBoundingClientRect()
		const arrowBoundingBox = arrowRef.current.getBoundingClientRect()

		const menuDistanceRight =
			menuBoundingBox.right -
			menuBoundingBox.width / HALF +
			HALF_MENU_WIDTH +
			DEFAULT_MENU_MARGIN_RIGHT

		const menuOffset = menuDistanceRight - window.innerWidth

		const arrowOffset =
			linkBoundingBox.left +
			linkBoundingBox.width / HALF -
			(arrowBoundingBox.left + arrowBoundingBox.width / HALF)

		if (arrowOffset > 0) {
			const menuLeft = Number.parseInt(menuRef.current?.style.left, DECIMAL)
			const arrowLeft = linkBoundingBox.width / HALF - menuLeft

			arrowRef.current?.style.setProperty(LEFT_STYLE_PROPERTY, `${arrowLeft}px`)

			return
		}

		if (menuOffset > 0) {
			const menuLeft = menuOffset - (HALF_MENU_WIDTH - HALF_ICON_WIDTH)
			const arrowLeft = linkBoundingBox.width / HALF - menuLeft

			menuRef.current?.style.setProperty(LEFT_STYLE_PROPERTY, `${menuLeft}px`)
			arrowRef.current?.style.setProperty(LEFT_STYLE_PROPERTY, `${arrowLeft}px`)
		}
	}, [isOpen, hasFavorites, menuRef.current, arrowRef.current, linkRef.current])

	return { repositionMenu }
}
