'use client'

import type { BUTTON_INSPIRATIONAL_VARIANT } from '@fukku/button/Inspirational/constants'
import type { ElementType } from 'react'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { LOGIN_URL } from '../constants'
import { StorePreviousUrl } from '../shared/StorePreviousUrl'

type LoginClientLinkProps = Omit<ClientLinkProps, 'href'> & {
	readonly linkComponent?: ElementType
	readonly variant?: BUTTON_INSPIRATIONAL_VARIANT
}

export function LoginClientLink({
	children,
	linkComponent,
	variant,
	...linkProps
}: LoginClientLinkProps) {
	const Component = linkComponent || ClientLink

	return (
		<>
			<StorePreviousUrl />
			<Component
				{...linkProps}
				withLegacyLocale={false}
				href={LOGIN_URL}
				variant={variant}
			>
				{children}
			</Component>
		</>
	)
}
