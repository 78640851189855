import { ClientLabel } from 'labels/client'
import { cx } from 'utils/cx'

import type { HintMessageProps } from '../types'

import texts from 'fukku/styles/texts.module.scss'
import styles from './Input.module.scss'

export function HintMessage({
	children,
	id,
	shouldRender,
	hintPlaceholders,
}: HintMessageProps) {
	if (!children || !shouldRender) {
		return null
	}

	return (
		<p className={cx(texts.bodyS, styles.hint, styles.showHint)} id={id}>
			<ClientLabel placeholderValues={hintPlaceholders}>{children}</ClientLabel>
		</p>
	)
}
