'use client'

import { InspirationalClientLink } from '@fukku/button/ClientLink/Inspirational'
import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { useTransparentProps } from 'header-utils/context/TransparentPropsContext/useTransparentProps'
import { IconFavoffL } from 'icons/components/IconFavoffL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { FavoritesClientLink } from 'links/favorites/client'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import {
	CommonGenericEvents,
	SectionParameters,
} from '../../constants/analytics'
import iconStyles from '../UserTools/Icons.module.scss'

export function Favorites() {
	const { t } = useLabels()
	const { isLargeOrGreater } = useResponsive()
	const { inspirationalVariant, iconColor } = useTransparentProps()

	const handleClick = () => {
		sendGenericEvent(CommonGenericEvents.ClickHeader, {
			section: SectionParameters.Favorites,
		})
	}

	return (
		<FavoritesClientLink
			className={iconStyles.link}
			onClick={handleClick}
			linkProps={{
				'data-testid': 'header.userMenu.favorites_any',
				'aria-label': t('accessibility.header.wishlist.icon'),
				rel: 'nofollow',
			}}
			linkComponent={isLargeOrGreater ? InspirationalClientLink : undefined}
			variant={inspirationalVariant}
		>
			<IconFavoffL className={iconStyles.icon} color={iconColor} />
			<span className={iconStyles.text}>{t('header.wishlist.text')}</span>
		</FavoritesClientLink>
	)
}
