'use client'

import { InspirationalClientLink } from '@fukku/button/ClientLink/Inspirational'
import { useTransparentProps } from 'header-utils/context/TransparentPropsContext/useTransparentProps'
import { IconAccountL } from 'icons/components/IconAccountL'
import { IconDownSmall } from 'icons/components/IconDownSmall'
import { IconUpSmall } from 'icons/components/IconUpSmall'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { LoginClientLink } from 'links/login/client'
import {
	MyAccountClientLink,
	type MyAccountClientLinkProps,
} from 'links/myAccount/client'
import { type ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { useSession } from 'session/src/hooks/useSession'

import { ACCOUNT_MENU_ID } from '../../constants/accountMenu'
import { DataTestIds } from '../../constants/testIds'

import iconStyles from '../Icons.module.scss'
import styles from './AccountIcon.module.scss'

type AccountIconProps = Readonly<{
	isExpanded?: boolean
	onClick?: () => void
}>

enum SessionMapKey {
	LOGGED = 'logged',
	NOT_LOGGED = 'notLogged',
}

type SessionMapper = Record<
	SessionMapKey,
	{
		text: string
		accessibilityText: string
		Component: typeof MyAccountClientLink | typeof LoginClientLink
	}
>

const SESSION_MAP: SessionMapper = {
	logged: {
		text: 'header.myAccount.text',
		accessibilityText: 'accessibility.header.myAccount.icon',
		Component: MyAccountClientLink,
	},
	notLogged: {
		text: 'header.myAccount.pushLogin.text',
		accessibilityText: 'accessibility.header.myAccount.pushLogin.icon',
		Component: LoginClientLink,
	},
}

export const AccountIcon = forwardRef<HTMLAnchorElement, AccountIconProps>(
	(
		{ isExpanded = false, onClick }: Readonly<AccountIconProps>,
		ref: ForwardedRef<HTMLAnchorElement>
	) => {
		const { isLargeOrGreater } = useResponsive()
		const { t } = useLabels()
		const { logged } = useSession()
		const { iconColor, inspirationalVariant } = useTransparentProps()

		const { text, accessibilityText, Component } =
			SESSION_MAP[logged ? SessionMapKey.LOGGED : SessionMapKey.NOT_LOGGED]
		const [labelAccount, setLabelAccount] = useState(t(text))

		useEffect(() => {
			setLabelAccount(t(text))
		}, [logged])

		const linkProps: MyAccountClientLinkProps['linkProps'] = {
			ref,
			rel: 'nofollow',
			'aria-label': t(accessibilityText),
			'data-testid': isLargeOrGreater
				? DataTestIds.IconLarge
				: DataTestIds.IconSmall,
		}

		return (
			<div className={styles.userLinkContainer}>
				<Component
					className={iconStyles.link}
					linkProps={linkProps}
					linkComponent={isLargeOrGreater ? InspirationalClientLink : undefined}
					variant={inspirationalVariant}
				>
					<IconAccountL className={iconStyles.icon} color={iconColor} />
					<span className={iconStyles.text}>{labelAccount}</span>
				</Component>
				{isLargeOrGreater && (
					<button
						type='button'
						className={styles.accessibilityButton}
						onClick={onClick}
						aria-label={labelAccount}
						aria-expanded={isExpanded}
						aria-controls={ACCOUNT_MENU_ID}
					>
						{isExpanded ? (
							<IconUpSmall width={20} height={20} />
						) : (
							<IconDownSmall width={20} height={20} />
						)}
					</button>
				)}
			</div>
		)
	}
)

AccountIcon.displayName = 'AccountIcon'
