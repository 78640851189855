'use client'

import type { BUTTON_INSPIRATIONAL_VARIANT } from '@fukku/button/Inspirational/constants'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { MY_ACCOUNT_LINK_HREF } from '../constants/myAccountLink'

export type MyAccountClientLinkProps = Omit<ClientLinkProps, 'href'> & {
	readonly linkComponent?: React.ElementType
	readonly variant?: BUTTON_INSPIRATIONAL_VARIANT
}

export function MyAccountClientLink({
	children,
	linkComponent,
	variant,
	...linkProps
}: MyAccountClientLinkProps) {
	const props: Omit<ClientLinkProps, 'prefetch'> = {
		...linkProps,
		children,
		href: MY_ACCOUNT_LINK_HREF,
		withLegacyLocale: false,
	}
	const Component = linkComponent || ClientLink

	return (
		<Component {...props} variant={variant}>
			{children}
		</Component>
	)
}
