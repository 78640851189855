'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useCallback, useState } from 'react'

import {
	DEFAULT_SUBSCRIPTION_BRAND,
	FormFieldNames,
	NewsletterAnalytics,
	type NewsletterOrigin,
} from '../constants'
import { newsletterSubscriptionService } from '../services/newsletterSubscriptionService'
import type {
	NewsletterSubscriptionServiceParams,
	NewsletterSubscriptionServiceResponse,
} from '../types/service'
import { isFieldsError } from '../utils/service/isFieldsError'

export const useSubscribe = (origin: NewsletterOrigin) => {
	const [subscribeError, setSubscribeError] = useState<FormFieldNames | null>(
		null
	)
	const [subscribeSuccess, setSubscribeSuccess] =
		useState<NewsletterSubscriptionServiceResponse | null>(null)
	const { brand, device, country, channel, isApp } = useMasterData()
	const { countryISO, languageISO } = country

	const resetSubscription = useCallback(() => {
		setSubscribeError(null)
		setSubscribeSuccess(null)
	}, [])

	const subscribe = useCallback(async (email: string) => {
		const payload: NewsletterSubscriptionServiceParams = {
			email,
			subscriptionOrigin: origin,
			countryIso: countryISO,
			languageIso: languageISO,
			navigation: {
				channel,
				storeId: (brand || DEFAULT_SUBSCRIPTION_BRAND).toLocaleLowerCase(),
				userAgent: {
					appView: isApp,
					deviceType: device,
				},
			},
		}

		resetSubscription()

		try {
			const response = await newsletterSubscriptionService(payload)

			setSubscribeSuccess(response)
		} catch (error) {
			if (isFieldsError(error as Error)) {
				return setSubscribeError(FormFieldNames.Email)
			}

			return setSubscribeError(FormFieldNames.Root)
		}

		sendGenericEvent(NewsletterAnalytics.NEWSLETTER_SUBSCRIBE, {
			method: NewsletterAnalytics.NEWSLETTER_NATIVE,
			newsletter_subscription: 1,
			location: origin,
		})

		return undefined
	}, [])

	return {
		resetSubscription,
		subscribe,
		subscribeError,
		subscribeSuccess,
	}
}
