'use client'

import { AccountActions } from 'account-actions/AccountActions/AccountActions'

export type AccountProps = {
	readonly hasFavorites: boolean
}

export const Account = ({ hasFavorites }: AccountProps) => (
	<AccountActions hasFavorites={hasFavorites} />
)
