import { ClientLabel } from 'labels/client'
import { cx } from 'utils/cx'

import type { ErrorMessageProps } from '../types'

import texts from 'fukku/styles/texts.module.scss'
import styles from './Input.module.scss'

export function ErrorMessage({
	children,
	id,
	errorPlaceholders,
	'data-testid': dataTestId,
}: ErrorMessageProps) {
	if (!children) {
		return null
	}

	return (
		<p
			className={cx(texts.bodyS, styles.errorMessage, styles.showError)}
			id={`${id}-error`}
			aria-live='polite'
			data-testid={dataTestId}
		>
			<ClientLabel placeholderValues={errorPlaceholders}>
				{children}
			</ClientLabel>
		</p>
	)
}
