export enum SubSectionParameters {
	HELP = 'help',
	LOGIN = 'login',
	LOGOUT = 'logout',
	LOYALTY = 'MLY',
	MY_PURCHASES = 'mypurchases',
	REGISTER = 'register',
}

export enum CommonGenericEvents {
	CLICK_HEADER = 'click_header',
	DISPLAY_HEADER = 'display_header',
}

export const SECTION_PARAMETERS_MY_ACCOUNT = 'myaccount'
