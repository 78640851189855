'use client'

import { useClientFetcher } from 'fetch-toolkit/client/useClientFetcher/useClientFetcher'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { Domains } from 'monitoring/constants/domains'
import useSWRImmutable from 'swr'
import { env } from 'utils/envManager'

import { MENU_SERVICE_URL } from '../../../constants/services'
import type { MenuService } from '../../../types/services/menu'

export function useMenuService(shouldFetch: boolean) {
	const {
		test,
		country: { countryISO, languageISO },
	} = useMasterData()
	const { clientFetcher } = useClientFetcher()

	const fetchUrl = new URL(
		`${env.NEXT_PUBLIC_SITE_BASE_URL}${MENU_SERVICE_URL}/v3/menu/${
			env.NEXT_PUBLIC_CHANNEL
		}/${countryISO.toUpperCase()}/${languageISO}${test ? '/test' : ''}`
	)

	return useSWRImmutable<MenuService | null>(
		shouldFetch ? fetchUrl.href : null,
		(url) =>
			clientFetcher({
				url: new URL(url),
				requestConfig: {
					method: 'GET',
				},
				errorConfig: {
					domain: Domains.Menu,
					message: `Failed to fetch menu for ${countryISO} and ${languageISO}`,
				},
			}),
		{ revalidateOnMount: true }
	)
}
