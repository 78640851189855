'use client'

import { SWR_OPTIONS } from 'cart/constants/swrOptions'
import type React from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { SWRConfig } from 'swr'

import { ShoppingCartLarge } from './ShoppingCartButton/ShoppingCartLarge'
import { ShoppingCartSmall } from './ShoppingCartButton/ShoppingCartSmall'

export function HeaderButton(): React.ReactNode {
	const { isLargeOrGreater } = useResponsive()

	return (
		<SWRConfig value={SWR_OPTIONS}>
			{isLargeOrGreater ? <ShoppingCartLarge /> : <ShoppingCartSmall />}
		</SWRConfig>
	)
}
